<template>
    <modal class="invoicingListModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">手工开票记录</div>
        <div class="modal-body">
            <div class="filter-bnts">
                <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()" >
                    <div class="title">
                        <span>今天<br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==true}" @click="queryType=true" >
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block" >
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==false'
                    > </el-date-picker>
                </div>
                <div class="inline-block search-flex">
                    <div class="bnt-search" @click="loadData()" >查询</div>
                </div>
            </div>
            <div class="table-box grey-table">
                <el-table ref="tableEl" border :data="tableListPage" height="400" style="width: 100%;"  
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                    <el-table-column type="index" label="序号" width="42" >
                        <template #default="scope">
                            {{scope.$index+pagesize*(currentPage-1)+1}}
                        </template>
                    </el-table-column>
                    <el-table-column type="edit" label="操作" width="70"> 
                        <template #default="scope">
                            <el-button  type="text" size="small" @click="printReceipt(scope.row)">补打</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column class-name="money" prop="Invoice_Money" label="开票金额" min-width="60" align="right" data-format="number"></el-table-column> 
                    <el-table-column prop="Operator_Name" label="操作人" min-width="50" align="left"></el-table-column>
                    <el-table-column prop="Operate_Time" label="操作时间" width="130" align="left" :formatter="dateFormat"></el-table-column>
                    <el-table-column prop="Remark" label="备注" min-width="100" align="left"> </el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                :data="tableData"
                @page-change="(list)=>tableListPage=list" 
                :pageSizes="[20,30,50,100]"
                :pageSize="pagesize"
                 @size-change="(size)=>pagesize=size"></table-page-btn>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">返回</button>
        </div>
    </modal>
</template>

<script>

// 开票记录
export default {
    name:'invoicingListModel',
    emits:["close","refresh"],
    props:{
        /**是否显示弹层 */
        isShow:{
            type:Boolean,
            default:false
        },
    },
    data(){
        return{
            userInfo:{},
            /**开票记录数据 */
            tableData:[],
            tableListPage:[],
            currentPage:1,
            pagesize:20,//每页的数据条数
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        let Rpt_Date=new Date(this.userInfo.Rpt_Date);
        Rpt_Date.setDate(Rpt_Date.getDate()-1);
        this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        hide(){
            this.$emit("close");
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /**初始化数据 */
        init(){
            this.loadData();
        },
        //加载数据
        loadData(){
            let userInfo=this.$auth.getUserInfo();
            this.tableData=[]
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,//操作员
                Operator_Pos:userInfo?.Site_Name, //操作站点
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetHandInputInvoices",param).then((data)=>{
                loading.close();
                console.log(data)
                if(data.ResponseHeader.ResultCode==0){
                    this.tableData=data.ResponseBody;
                }else{
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('查询失败：'+e);
                console.log('查询失败：'+e);
            })
        },
        /**重印小票 */
        printReceipt(item){
            const loading = this.$loading({
                text: "请求打印数据中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.ReprintHandInputInvoice",{
                Operator_Name:userInfo?.Login_Name,
                User_ID:userInfo?.User_ID,
                Invoice_ID:item.Invoice_ID
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(d.ResponseBody){
                        this.$webBrowser.posPrint(d.ResponseBody);
                        this.$message.success("已发送打印指令");
                    }else{
                        this.$message.error('请求设备没有相关打印设置!');
                    }
                    
                }else{
                    this.$message.error("请求打印数据失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('请求打印数据失败：'+e);
                console.log('请求打印数据失败：'+e);
            })
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        }
    }
}
</script>

<style lang="scss">
@import './invoicingListModel.scss'
</style>