<template>
    <modal class="customInvoicingModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">手工登记开票</div>
        <div class="modal-body">
            <div class="flex-box" ref="rightInput">
                <div class="left-box">
                    <div class="tr-li td-box">
                        <div class="lable-txt">开票金额：</div>
                        <div class="from-input"><input-pattern ref="invoiceMoney" class="money key-focus" type="text" pattern="number" v-model="invoiceMoney" /></div>
                    </div>
                    <div class="tr-li td-box">
                        <div class="lable-txt">开票备注：</div>
                        <div class="from-input">
                            <textarea class="key-focus" placeholder="30字以内" v-model="remark" maxlength="30"/>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn selected" @click="recordShow=true">查看开票记录</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
        <modal-load :isShow="recordShow" isUnload>
            <invoicing-list-model :isShow="recordShow" @close="recordShow=false"/>
        </modal-load>
    </modal>
</template>

<script>

import invoicingListModel from './invoicingListModel/invoicingListModel.vue';
import {initKeyBoard} from '/src/common';
//手工登记开票
export default {
    name:"customInvoicingModel",
    components:{
        invoicingListModel
    },
    emits:["close"],
    props:{ 
        /**是否显示弹层 */
        isShow:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            //开票记录弹层
            recordShow:false,
            /**开票金额 */
            invoiceMoney:0,
            //备注
            remark:""
        }
    },
    mounted(){
        this.$nextTick(()=>{
            initKeyBoard.call(this,this.$refs.rightInput);
            this.$refs.invoiceMoney.focus();
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            //开票金额
            this.invoiceMoney=0;
            //备注
            this.remark="";
        },
        /**关闭 */
        hide(){
            this.$emit("close");
        },
        /**点击确认 */
        confirm(){
            if(!(this.invoiceMoney>0)){
                this.$message.warning("本次开票金额必须大于0!");
                this.$refs.invoiceMoney.focus();
                return;
            }
            this.userInfo=this.$auth.getUserInfo();
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,//操作员
                Operator_Pos:this.userInfo?.Site_Name, //操作站点
                Invoice_Money:this.invoiceMoney,//开票金额
                Remark:this.remark
            }
            this.$confirm("您确认本次开"+param.Invoice_Money+"元发票吗？", "提示", {
				confirmButtonText: "确定",
				cancelButtonText:"取消",
				callback: (name) => {
					if(name=='confirm'){
						this.IssueHandInputInvoice(param);
					}
				},
			});
        },
        //开票
        IssueHandInputInvoice(param){
            const loading = this.$loading({
                text: "开票请求中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.IssueHandInputInvoice",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.init();
                    this.$message.success("开票成功");
                    if(d.ResponseBody){
                        this.$webBrowser.posPrint(d.ResponseBody);
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('开票失败：'+e);
                console.log('开票失败：',e);
            })
        }
    }
}
</script>

<style lang="scss">
@import "./customInvoicingModel.scss";
</style>